export enum ApiPath {
  UserSignUp = '/users/api/v1/users/signUp',
  LegalEntitySignUp = '/users/api/v1/company/signUp',
  GetUploadPolicy = '/users/api/v1/userVerification/getUploadPolicy',
  SendDocumentCaptureLink = '/users/api/v1/userVerification/sendDocumentCaptureLink',
  UserVerificationDataUpdate = '/users/api/v1/userVerification/update',
  UserVerificationUpgrade = '/users/api/v1/userVerification/upgrade',
  UsersSmsMfaDisable = '/users/api/v1/users/disableSmsMfa',
  UsersSmsMfaEnable = '/users/api/v1/users/enableSmsMfa',
  UsersUserExists = '/users/api/v1/users/userExists',
  UsersVerifyPhoneNumber = '/users/api/v1/users/verifyPhoneNumber',
  UsersWalletBalance = '/api/v1/users/walletBalance',
  UsersDismissBanner = '/users/api/v1/users/dismissBanner',
  UserDetails = '/users/api/v1/users/details',
  GetUserVerificationData = '/users/api/v1/userVerification',
  GetFrameworkAgreementPreview = '/docs/api/v1/framework-agreement/preview',
  GetLatestSignedFrameworkAgreement = '/docs/api/v1/framework-agreement/get',
  SignFrameworkAgreement = '/docs/api/v1/framework-agreement/sign',
  TaxReports = '/docs/api/v1/tax-reports',
  LoanAgreementPreview = '/docs/api/v1/loan-agreement/preview',
  LoanAgreementUrl = '/docs/api/v1/loan-agreement/file-url',
  LegalEntityRequiredDocuments = '/docs/api/v1/company/verification',
  RequestVerificationCode = '/docs/api/v1/verification-code/send',
  ResendVerificationCode = '/docs/api/v1/verification-code/resend',
  AgreementsList = '/docs/api/v1/agreement',
  AgreementsGetFileUrl = '/docs/api/v1/agreement/get-file-url',
  GetSignedUserContract = '/docs/api/v1/agreement/get-user-agreement',
  InvestIntoProperty = '/step-functions/invest',
  InvestIntoParticipationProperty = '/step-functions/participation-investment',
  InvestIntoSecondaryMarketOffer = '/step-functions/purchase-offered-investment',
  BidIntoSecondaryMarketOffer = '/step-functions/bid-on-secondary-market-offer',
  OfferInvestment = '/step-functions/offer-investment',
  CancelSecondaryMarketOffers = '/step-functions/cancel-secondary-market-offers',
  AcceptSecondaryMarketOfferBid = '/step-functions/accept-secondary-market-bid',
  DeclineSecondaryMarketOfferBid = '/step-functions/decline-secondary-market-bid',
  WithdrawFromWallet = '/step-functions/withdraw-from-wallet',
  WithdrawFromInvestment = '/step-functions/withdraw-from-investment',
  InvestorSuitabilityQuestionnaire = '/users/api/v1/user-suitability/part-one-questionnaire',
  InvestorSuitabilityQuestionnaireSubmit = '/users/api/v1/user-suitability/part-one-questionnaire',
  InvestorQuestionnairePartTwo = '/users/api/v1/user-suitability/part-two-questionnaire',
  InvestorQuestionnairePartTwoSubmit = '/users/api/v1/user-suitability/part-two-questionnaire',
  AbilityToBearLossQuestionnaire = '/users/api/v1/user-financial-data/ability-to-bear-loss-questionnaire',
  AbilityToBearLossQuestionnaireSubmit = '/users/api/v1/user-financial-data',
  TerminateAccount = '/users/api/v1/users/terminate-account',
  MerkCompanyInfo = '/users/api/v1/company/business-registry-info',
  CompanyInfo = '/users/api/v1/company/info',
  CreateReferralCodeConnection = '/affiliate/api/v1/emailUserReferralLink/create',
  GetReferralCodeConnection = '/affiliate/api/v1/emailUserReferralLink/get',
  CalculateNetWorth = '/users/api/v1/user-financial-data/calculate-net-worth',
  InvestmentThresholds = '/users/api/v1/user-financial-data/investment-thresholds',
  UserTimestamps = '/users/api/v1/user-timestamps',
  UserLevels = '/users/api/v1/configuration/user-levels',
  UpdatePreferredLocale = '/users/api/v1/users/updatePreferredLocale',
  GetUserReferralConditions = '/affiliate/api/v1/userReferralConditions',
  VerifySMSCode = '/users/api/v1/verification-code/verify',
}

export enum QueryKeys {
  AbilityToBearLossQuestionnaire = 'investorAbilityToBearLossQuestionnaire',
  AbilityToBearLossQuestionnaireSubmit = 'abilityToBearLossQuestionnaireSubmit',
  ActiveSecondaryMarketRemainingOffersSums = 'activeSecondaryMarketRemainingOffersSums',
  Agreements = 'agreements',
  AllProjectsReturns = 'allProjectsReturns',
  AnnualPercentageYield = 'annualPercentageYield',
  AutoinvestConfig = 'autoinvestConfig',
  AutoinvestStatistics = 'autoinvestStatistics',
  AverageYieldMonthlyHistory = 'averageYieldMonthlyHistory',
  CountPageableProperties = 'countPageableProperties',
  CountPortfolioItems = 'countPortfolioItems',
  CountSecondaryMarketBids = 'countSecondaryMarketBids',
  CountSecondaryMarketItems = 'countSecondaryMarketItems',
  CurrentAndExpectedReturns = 'currentAndExpectedReturns',
  FrameworkAgreementPreview = 'frameworkAgreementPreview',
  GetCompanyInfo = 'getCompanyInfo',
  GetMerkCompanyInfo = 'getMerkCompanyInfo',
  GetUserPreferences = 'getUserPreferences',
  GetUserReferralCodeConnection = 'getUserReferralCodeConnection',
  HasPermissionToInviteThroughReferral = 'hasPermissionToInviteThroughReferral',
  Installments = 'installments',
  InvestedProperties = 'investedProperties',
  InvestedPropertyRoundRepaymentStatistics = 'investedPropertyRoundRepaymentStatistics',
  InvestmentRoundFees = 'investmentRoundFees',
  InvestmentRoundActivities = 'investmentRoundActivities',
  InvestmentRoundsSecondaryMarketInfo = 'investmentRoundsSecondaryMarketInfo',
  InvestmentRoundsSecondaryMarketInfos = 'investmentRoundsSecondaryMarketInfos',
  InvestmentThresholds = 'investmentThresholds',
  InvestorQuestionnaire = 'investorQuestionnaire',
  InvestorQuestionnairePartTwo = 'investorQuestionnairePartTwo',
  InvestorQuestionnairePartTwoSubmit = 'investorQuestionnairePartTwoSubmit',
  InvestorQuestionnaireSubmit = 'investorQuestionnaireSubmit',
  lastWalletUpdate = 'lastWalletUpdate',
  LatestAutoinvestStrategy = 'latestAutoinvestStrategy',
  LatestSignedFrameworkAgreement = 'latestSignedFrameworkAgreement',
  MonthlyGrossReturns = 'monthlyGrossReturns',
  MonthlyProfit = 'monthlyProfit',
  MyFinancialPlans = 'myFinancialPlans',
  OrderInfo = 'orderInfo',
  PageOfProperties = 'pageOfProperties',
  PartnerRelatedProjects = 'partnerRelatedProjects',
  PortfolioSizeMonthlyHistory = 'portfolioSizeMonthlyHistory',
  PortfolioStatistics = 'portfolioStatistics',
  Property = 'property',
  PropertyInvestmentRoundActivity = 'propertyInvestmentRoundActivity',
  PropertyInvestments = 'propertyInvestments',
  SecondaryMarketBids = 'secondaryMarketBids',
  SecondaryMarketItems = 'secondaryMarketItems',
  TaxReport = 'taxReport',
  TotalProfit = 'totalProfit',
  TotalUserLevelBonusYield = 'totalUserLevelBonusYield',
  UploadPolicy = 'uploadPolicy',
  UserBankAccounts = 'userBankAccounts',
  UserContract = 'userContract',
  UserDetails = 'userDetails',
  UserInvestmentStats = 'userInvestmentStats',
  UserLevels = 'userLevels',
  UserReferralConditions = 'userReferralConditions',
  UserTimestamps = 'userTimestamps',
  UserVerificationData = 'userVerificationData',
  UserVerificationPhotos = 'userVerificationPhotos',
  Wallet = 'wallet',
  WalletHistory = 'walletHistory',
}

export enum ApiNames {
  MonorepoApiEndpoint = 'investown-mono',
}

export enum ApiErrorCode {
  NotFound = 'NotFound',
  UserIsLockedError = 'UserIsLockedError',
  UserNotFoundError = 'UserNotFoundError',
  OfferAlreadySold = 'OfferAlreadySold',
  OperationIsNotAllowedError = 'OperationIsNotAllowedError',
  InsufficientFundsError = 'InsufficientFundsError',
  RoundNotFoundError = 'RoundNotFoundError',
  InvalidPropertyStateError = 'InvalidPropertyStateError',
  AmountNotAvailableError = 'AmountNotAvailableError',
  RoundClosedError = 'RoundClosedError',
  MinInvestmentError = 'MinInvestmentError',
  UserInvestmentLimitError = 'UserInvestmentLimitError',
  TooManyTokensGenerated = 'TooManyTokensGenerated',
  FeatureTurnedOffError = 'FeatureTurnedOffError',
  UnsupportedAutoinvestSchemaVersionError = 'UnsupportedAutoinvestSchemaVersion',
}
